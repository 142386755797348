import React, { useEffect, useMemo, useState } from "react";
import { Container, Subscription, SubscriptionTag } from "./styles";
import { get_token_and_extid } from "../../../auth";

export const Subscriptions = ({ onPlanSelect }) => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    async function loadSubscriptions() {
      const { token } = get_token_and_extid();

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/payments/products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      setSubscriptions(data);
    }

    loadSubscriptions();
  }, []);

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format;
  }, []);

  return (
    <Container>
      {subscriptions.map((subscription) => {
        const attributes = Object.entries(subscription.metadata)
          .filter(([key]) => key.includes("attrs"))
          .map(([, value]) => value);
        const amountInDollars = subscription.price / 100;
        const tag = subscription.metadata.tag;
        const isMostPopular = tag === "Most popular";

        return (
          <Subscription key={subscription.id} mostPopular={isMostPopular}>
            {tag && <SubscriptionTag>{tag}</SubscriptionTag>}

            <header>
              <h1>{subscription.name}</h1>

              <div>
                <strong>{numberFormat(amountInDollars)}</strong>
                <span>/ Month</span>
              </div>
            </header>

            <main>
              {attributes.map((attr) => (
                <div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#86E7B8" />
                    <path
                      d="M12.0343 4.99108C11.9745 4.93078 11.9034 4.88292 11.825 4.85026C11.7466 4.8176 11.6625 4.80078 11.5776 4.80078C11.4926 4.80078 11.4086 4.8176 11.3302 4.85026C11.2518 4.88292 11.1806 4.93078 11.1208 4.99108L6.32788 9.79043L4.31422 7.77033C4.25212 7.71034 4.17882 7.66318 4.09849 7.63152C4.01817 7.59987 3.9324 7.58434 3.84607 7.58584C3.75975 7.58733 3.67456 7.60581 3.59538 7.64022C3.5162 7.67464 3.44457 7.72431 3.38459 7.78641C3.3246 7.84851 3.27744 7.92181 3.24578 8.00213C3.21413 8.08246 3.1986 8.16823 3.2001 8.25456C3.20159 8.34088 3.22007 8.42607 3.25449 8.50525C3.2889 8.58443 3.33857 8.65606 3.40067 8.71604L5.87111 11.1865C5.93092 11.2468 6.00207 11.2946 6.08047 11.3273C6.15887 11.36 6.24296 11.3768 6.32788 11.3768C6.41281 11.3768 6.4969 11.36 6.5753 11.3273C6.6537 11.2946 6.72485 11.2468 6.78466 11.1865L12.0343 5.9368C12.0996 5.87655 12.1518 5.80344 12.1874 5.72205C12.2231 5.64067 12.2415 5.55279 12.2415 5.46394C12.2415 5.37509 12.2231 5.28721 12.1874 5.20583C12.1518 5.12444 12.0996 5.05133 12.0343 4.99108Z"
                      fill="#252527"
                    />
                  </svg>

                  <span key={attr}>{attr}</span>
                </div>
              ))}
            </main>

            <footer>
              <button
                type="button"
                onClick={() => onPlanSelect(subscription)}
              >
                Continue
              </button>
            </footer>
          </Subscription>
        );
      })}
    </Container>
  );
};
