import React, { useState, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../components/Header'
import { forgot_password, is_signin } from '../auth/index'

const ForgotPassword = (req) => {
  const [form_data, set_form_data] = useState({
    email: ''
  })
  const [error_message, set_error_message] = useState('')
  const [success_message, set_success_message] = useState('')
  const [to_redirect] = useState(false)

  const {
    email
  } = form_data

  const handle_form_change = name => event => {
    set_form_data({
      ...form_data,
      [name]: event.target.value
    })
  }

  const submit_form = event => {
    event.preventDefault()
    if (form_data.email) {
      forgot_password(form_data)
        .then(user_extid => {
            set_success_message('Check your email for a link to reset your password.')
            setTimeout(() => {
              set_success_message(null)
            }, 5000);
        })
        .catch(err => {
          set_form_data({
            ...form_data,
            password: ''
          })
          set_error_message('Invalid email. Please sign up or try again.')
        })
    } else {
      set_error_message('Email is required.')
    }
  }

  return (
    <Fragment>
      { is_signin() && <Redirect to="/dashboard" /> }
      { to_redirect && <Redirect to="/dashboard" /> }
      <Header create_acount_btn />
      <div className="center">
        <div className="modal">
          <h1 className="heading">
            Reset Password
          </h1>
          <div className="form w-form">
            <form
              className="access-form"
            >
              <div className="contact-form-grid">
                <p>Enter your email and we'll send you a link to reset your password.</p>
                { success_message && <div className="warning-box">{ success_message }</div> }
                <input
                  type="email"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Email"
                  placeholder="Email"
                  onChange={handle_form_change('email')}
                  value={email}
                />
              </div>
              <input
                type="submit"
                value="Send Link"
                className="button large w-button"
                onClick={submit_form}
              />
              { error_message && <small className="error-message">{ error_message }</small> }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ForgotPassword
