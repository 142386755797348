import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const Subscription = styled.div`
  background-color: ${props => props.mostPopular ? '#252527' : '#DFE1E3'};

  padding: 24px 32px;

  border-radius: 10px;

  position: relative;

  > header {
    > h1 {
      font-size: 20px;
      font-weight: 500;
      
      color: ${props => props.mostPopular ? '#fff' : '#252527'};

      opacity: .8;

      margin: 0;

      line-height: normal;
    }

    > div {
      display: flex;
      align-items: flex-end;

      padding-bottom: 8px;
      margin-bottom: 8px;
      margin-top: 16px;

      border-bottom: 1px solid ${props => props.mostPopular ? '#fff' : '#252527'};

      > strong {
        font-size: 48px;
        font-weight: 500;

        color: ${props => props.mostPopular ? '#fff' : '#252527'};

        line-height: 39px;
      }

      > span {
        font-size: 18px;
        font-weight: 500;

        color: ${props => props.mostPopular ? '#fff' : '#252527'};

        opacity: .8;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    margin-top: 16px;

    gap: 6px;

    > div {
      display: flex;
      align-items: center;
      gap: 10px;

      > span {
        color: ${props => props.mostPopular ? '#fff' : '#252527'};
      }
    }
  }

  > footer {
    margin-top: 16px;

    > button {
      background-color: #91D5BD;
      color: #252527;

      font-size: 16px;
      font-weight: 700;

      height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      border-radius: 4px;
    }
  }
`;

export const SubscriptionTag = styled.div`
  padding: 6px 15px;

  border: 1px solid #FFD143;

  color: #FFD143;

  font-size: 14px;
  font-weight: 700;

  border-radius: 360px;

  position: absolute;

  top: 12px;
  right: 12px;
`;
